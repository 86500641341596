/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionMetaData } from './action-meta-data';
import { NameIdPojo } from './name-id-pojo';


export interface ProcessActionDefinitionPojo { 
    id?: number;
    createdAt?: string;
    createdBy?: NameIdPojo;
    name?: string;
    ordinal?: number;
    baselineRunTimeInMinutes?: number;
    verbCommitmentPhrase?: string;
    processDefinition?: NameIdPojo;
    skills?: Array<NameIdPojo>;
    tools?: Array<NameIdPojo>;
    roles?: Array<NameIdPojo>;
    certifications?: Array<NameIdPojo>;
    trainings?: Array<NameIdPojo>;
    standards?: Array<NameIdPojo>;
    outputStatus?: Array<NameIdPojo>;
    workflowDiagramSymbol?: ProcessActionDefinitionPojo.WorkflowDiagramSymbolEnum;
    metaData?: ActionMetaData;
}
export namespace ProcessActionDefinitionPojo {
    export type WorkflowDiagramSymbolEnum = 'TERMINAL' | 'PROCESS' | 'ARROW' | 'DECISION' | 'INPUT_OUTPUT_LEFT' | 'INPUT_OUTPUT_RIGHT' | 'ON_PAGE_CONNECTOR' | 'OFF_PAGE_CONNECTOR' | 'DOCUMENT' | 'MULTIPLE_DOCUMENTS' | 'PREPARATION' | 'MANUAL_OPERATION' | 'SUMMONING_JUNCTION' | 'MERGE' | 'COLLATE' | 'SORT' | 'OR' | 'LOOP_LIMIT' | 'DELAY' | 'INTERNAL_STORAGE' | 'DATA_STORAGE' | 'DATABASE' | 'DISPLAY' | 'HARD_DISK' | 'ALTERNATE_PROCESS' | 'PREDEFINED_PROCESS';
    export const WorkflowDiagramSymbolEnum = {
        Terminal: 'TERMINAL' as WorkflowDiagramSymbolEnum,
        Process: 'PROCESS' as WorkflowDiagramSymbolEnum,
        Arrow: 'ARROW' as WorkflowDiagramSymbolEnum,
        Decision: 'DECISION' as WorkflowDiagramSymbolEnum,
        InputOutputLeft: 'INPUT_OUTPUT_LEFT' as WorkflowDiagramSymbolEnum,
        InputOutputRight: 'INPUT_OUTPUT_RIGHT' as WorkflowDiagramSymbolEnum,
        OnPageConnector: 'ON_PAGE_CONNECTOR' as WorkflowDiagramSymbolEnum,
        OffPageConnector: 'OFF_PAGE_CONNECTOR' as WorkflowDiagramSymbolEnum,
        Document: 'DOCUMENT' as WorkflowDiagramSymbolEnum,
        MultipleDocuments: 'MULTIPLE_DOCUMENTS' as WorkflowDiagramSymbolEnum,
        Preparation: 'PREPARATION' as WorkflowDiagramSymbolEnum,
        ManualOperation: 'MANUAL_OPERATION' as WorkflowDiagramSymbolEnum,
        SummoningJunction: 'SUMMONING_JUNCTION' as WorkflowDiagramSymbolEnum,
        Merge: 'MERGE' as WorkflowDiagramSymbolEnum,
        Collate: 'COLLATE' as WorkflowDiagramSymbolEnum,
        Sort: 'SORT' as WorkflowDiagramSymbolEnum,
        Or: 'OR' as WorkflowDiagramSymbolEnum,
        LoopLimit: 'LOOP_LIMIT' as WorkflowDiagramSymbolEnum,
        Delay: 'DELAY' as WorkflowDiagramSymbolEnum,
        InternalStorage: 'INTERNAL_STORAGE' as WorkflowDiagramSymbolEnum,
        DataStorage: 'DATA_STORAGE' as WorkflowDiagramSymbolEnum,
        Database: 'DATABASE' as WorkflowDiagramSymbolEnum,
        Display: 'DISPLAY' as WorkflowDiagramSymbolEnum,
        HardDisk: 'HARD_DISK' as WorkflowDiagramSymbolEnum,
        AlternateProcess: 'ALTERNATE_PROCESS' as WorkflowDiagramSymbolEnum,
        PredefinedProcess: 'PREDEFINED_PROCESS' as WorkflowDiagramSymbolEnum
    };
}


