import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-view-watch-time',
  templateUrl: './view-watch-time.component.html',
  styleUrls: ['./view-watch-time.component.css']
})
export class ViewWatchTimeComponent {
  @Input()
  time: number;

  @Input()
  label = '';

  @Input()
  showUnitLabel = false;

  @Input()
  showDays = false;

  @Input()
  colorScheme: 'light' | 'dark' = 'dark';
}
