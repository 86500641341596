/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ArpDto { 
    reasons?: Array<ArpDto.ReasonsEnum>;
    actionSteps?: Array<string>;
    newCompletionTime?: string;
}
export namespace ArpDto {
    export type ReasonsEnum = 'health_challenge' | 'task_re_prioritization_by_line_manager' | 'family_emergency' | 'act_of_god' | 'emergency_shut_down_of_gemma' | 'suspension_from_work' | 'debugging' | 'wrong_requirement_rework' | 'dependency_on_another_players_completion_waiting' | 'steep_learning_curve_knowledge_gap';
    export const ReasonsEnum = {
        HealthChallenge: 'health_challenge' as ReasonsEnum,
        TaskRePrioritizationByLineManager: 'task_re_prioritization_by_line_manager' as ReasonsEnum,
        FamilyEmergency: 'family_emergency' as ReasonsEnum,
        ActOfGod: 'act_of_god' as ReasonsEnum,
        EmergencyShutDownOfGemma: 'emergency_shut_down_of_gemma' as ReasonsEnum,
        SuspensionFromWork: 'suspension_from_work' as ReasonsEnum,
        Debugging: 'debugging' as ReasonsEnum,
        WrongRequirementRework: 'wrong_requirement_rework' as ReasonsEnum,
        DependencyOnAnotherPlayersCompletionWaiting: 'dependency_on_another_players_completion_waiting' as ReasonsEnum,
        SteepLearningCurveKnowledgeGap: 'steep_learning_curve_knowledge_gap' as ReasonsEnum
    };
}


