import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appSelectDropdownState]'
})
export class SelectDropdownStateDirective implements OnChanges {

    @Input()
    state: SelectDropdownDataState = SelectDropdownDataState.IDLE;

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['state']) {
            console.log('state change ', changes['state'])
            this.updateDropdown();
        }
    }

    private updateDropdown(): void {
        console.log('state ', this.state)
        const select = this.el.nativeElement as HTMLSelectElement;

        // Clear previous state messages if necessary
        while (select.options.length > 0) {
            select.remove(0);
        }

        switch (this.state) {
            case SelectDropdownDataState.LOADING:
                this.addOption('Loading...', true);
                this.renderer.setAttribute(select, 'disabled', 'true');
                break;

            case SelectDropdownDataState.ERROR:
                this.addOption('Error loading data', true);
                this.renderer.setAttribute(select, 'disabled', 'true');
                break;

            case SelectDropdownDataState.EMPTY:
                this.addOption('No data available', true);
                this.renderer.setAttribute(select, 'disabled', 'true');
                break;

            case SelectDropdownDataState.SUCCESS:
                this.renderer.removeAttribute(select, 'disabled');
                break;

            default:
                this.addOption('Select an option', true);
                this.renderer.removeAttribute(select, 'disabled');
        }
    }

    private addOption(text: string, disabled: boolean = false) {
        const option = this.renderer.createElement('option') as HTMLOptionElement;
        const textNode = this.renderer.createText(text);
        this.renderer.appendChild(option, textNode);
        if (disabled) {
            this.renderer.setAttribute(option, 'disabled', 'true');
        }
        this.renderer.appendChild(this.el.nativeElement, option);
    }
}

export enum SelectDropdownDataState {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
    EMPTY = 'empty',
    IDLE = 'idle'
}
