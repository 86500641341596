/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalAccount } from './portal-account';
import { NameIdPojo } from './name-id-pojo';
import { PortalUser } from './portal-user';


export interface WigPojo { 
    id?: number;
    name?: string;
    description?: string;
    startDate?: string;
    completedAt?: string;
    status?: WigPojo.StatusEnum;
    score?: number;
    dueAt?: string;
    externalSystemIssueUrl?: string;
    completed?: boolean;
    createdAt?: string;
    createdBy?: PortalUser;
    parentWigId?: number;
    portalAccount?: PortalAccount;
    completionStatus?: WigPojo.CompletionStatusEnum;
    decompositionScore?: number;
    scoreboardDecompositionScore?: number;
    winning?: boolean;
    platform?: NameIdPojo;
    numberOfExpectedCustomerSignOffs?: number;
    numberOfSignedCustomerSignOffs?: number;
    benchmark?: number;
    numberOfPacsIssued?: number;
    numberOfPacs?: number;
    playerIdsInvolved?: Array<number>;
    reviewStatus?: WigPojo.ReviewStatusEnum;
    reviewedAt?: string;
    reviewer?: NameIdPojo;
    reasonForReview?: string;
    sponsorType?: WigPojo.SponsorTypeEnum;
    wigSponsorPojo?: NameIdPojo;
    wigDocumentPojo?: NameIdPojo;
}
export namespace WigPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DELETED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deleted: 'DELETED' as StatusEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
    export type SponsorTypeEnum = 'ORGANISATION' | 'CUSTOMER';
    export const SponsorTypeEnum = {
        Organisation: 'ORGANISATION' as SponsorTypeEnum,
        Customer: 'CUSTOMER' as SponsorTypeEnum
    };
}


