/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { ProcessModelPojo } from './process-model-pojo';


export interface ExecutionProcessDefinitionPojo { 
    id?: number;
    name?: string;
    processVersion?: string;
    processModel?: ProcessModelPojo;
    processName?: string;
    processOwner?: NameIdPojo;
    baselineRuntimeInMinutes?: number;
    locked?: boolean;
    identifier?: string;
}

