/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LeadMeasureCommitmentScorePojo } from './lead-measure-commitment-score-pojo';
import { LocalTime } from './local-time';
import { NameIdPojo } from './name-id-pojo';
import { ProcessActionExecutionInstancePojo } from './process-action-execution-instance-pojo';
import { CustomerValuePojo } from './customer-value-pojo';
import { ExecutionEntityPojo } from './execution-entity-pojo';
import { LeadMeasurePojo } from './lead-measure-pojo';
import { BlockerPojo } from './blocker-pojo';
import { ArpPojo } from './arp-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface LeadMeasureCommitmentPojo { 
    id?: number;
    leadMeasure?: LeadMeasurePojo;
    targetValue?: number;
    actualValue?: number;
    variance?: number;
    effortType?: LeadMeasureCommitmentPojo.EffortTypeEnum;
    player?: PortalUserPojo;
    executionDomainPojo?: ExecutionEntityPojo;
    leadMeasureCategory?: LeadMeasureCommitmentPojo.LeadMeasureCategoryEnum;
    blockerPojoList?: Array<BlockerPojo>;
    createdAt?: string;
    completionStatus?: LeadMeasureCommitmentPojo.CompletionStatusEnum;
    currentValue?: number;
    benchmarkValue?: number;
    startTime?: LocalTime;
    endTime?: LocalTime;
    day?: string;
    comment?: string;
    customerValuePojo?: CustomerValuePojo;
    lagType?: LeadMeasureCommitmentPojo.LagTypeEnum;
    scorePojo?: LeadMeasureCommitmentScorePojo;
    qualifiedLeadMeasureName?: string;
    processActionDefinition?: NameIdPojo;
    workProductInput?: NameIdPojo;
    hasArp?: boolean;
    wigCommitment?: boolean;
    arp?: ArpPojo;
    department?: NameIdPojo;
    processModel?: NameIdPojo;
    processDefinition?: NameIdPojo;
    processActionExecutionInstance?: ProcessActionExecutionInstancePojo;
}
export namespace LeadMeasureCommitmentPojo {
    export type EffortTypeEnum = 'NEW' | 'UPDATE';
    export const EffortTypeEnum = {
        New: 'NEW' as EffortTypeEnum,
        Update: 'UPDATE' as EffortTypeEnum
    };
    export type LeadMeasureCategoryEnum = 'WIG' | 'WHIRLWIND';
    export const LeadMeasureCategoryEnum = {
        Wig: 'WIG' as LeadMeasureCategoryEnum,
        Whirlwind: 'WHIRLWIND' as LeadMeasureCategoryEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED' | 'PENDING_REVIEW' | 'NOT_STARTED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum,
        ProductionAuthorization: 'PRODUCTION_AUTHORIZATION' as CompletionStatusEnum,
        Released: 'RELEASED' as CompletionStatusEnum,
        Executed: 'EXECUTED' as CompletionStatusEnum,
        PendingReview: 'PENDING_REVIEW' as CompletionStatusEnum,
        NotStarted: 'NOT_STARTED' as CompletionStatusEnum
    };
    export type LagTypeEnum = 'PERFORMANCE' | 'PRODUCTIVITY';
    export const LagTypeEnum = {
        Performance: 'PERFORMANCE' as LagTypeEnum,
        Productivity: 'PRODUCTIVITY' as LagTypeEnum
    };
}


