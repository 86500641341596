/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WigDto { 
    name: string;
    startDate: string;
    dueAt?: string;
    description?: string;
    externalSystemIssueUrl?: string;
    parentWigId?: number;
    isCompleted?: boolean;
    customerValueIds?: Array<number>;
    platformId: number;
    wigSponsorTypeConstant?: WigDto.WigSponsorTypeConstantEnum;
    reviewStatusConstant?: WigDto.ReviewStatusConstantEnum;
    customerOrganizationId?: number;
}
export namespace WigDto {
    export type WigSponsorTypeConstantEnum = 'ORGANISATION' | 'CUSTOMER';
    export const WigSponsorTypeConstantEnum = {
        Organisation: 'ORGANISATION' as WigSponsorTypeConstantEnum,
        Customer: 'CUSTOMER' as WigSponsorTypeConstantEnum
    };
    export type ReviewStatusConstantEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusConstantEnum = {
        Approved: 'APPROVED' as ReviewStatusConstantEnum,
        Pending: 'PENDING' as ReviewStatusConstantEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusConstantEnum
    };
}


