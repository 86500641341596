/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { QueryResultsWorkProductDefinitionPojo } from '../model/models';
import { WorkProductDefinitionDto } from '../model/models';
import { WorkProductDefinitionPojo } from '../model/models';
import { WorkProductSectionDefinitionDetailDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateNewVersionRequestParams {
    id: number;
}

export interface CreateWorkProductDefinitionRequestParams {
    workProductDefinitionDto: WorkProductDefinitionDto;
}

export interface DeleteWorkProductDefinitionRequestParams {
    id: number;
}

export interface GetAllWorkProductDefinitionRequestParams {
    approvalStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
}

export interface GetOutputWorkProductDefinitionByProcessDefinitionIdRequestParams {
    id: number;
}

export interface GetOutputWorkProductDefinitionByProcessExecutionInstanceIdRequestParams {
    id: number;
}

export interface GetWorkProductDefinitionByIdRequestParams {
    id: number;
}

export interface SaveWorkProductSectionDefinitionDetailsRequestParams {
    id: number;
    requestApproval: boolean;
    workProductSectionDefinitionDetailDto: Array<WorkProductSectionDefinitionDetailDto>;
    selectedApprover?: number;
}

export interface SearchWorkProductDefinitionRequestParams {
    identifier?: string;
    reviewedAt?: string;
    reviewer?: string;
    footerInfo?: string;
    versionNumber?: number;
    reasonForReview?: string;
    portalAccount?: string;
    createdAt?: string;
    versionNumberDisplayName?: string;
    createdBy?: string;
    previousVersion?: string;
    name?: string;
    workProductDocumentStyle?: string;
    logo?: string;
    reviewStatus?: 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    id?: number;
    complete?: boolean;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    limit?: number;
    createdBefore?: string;
    keyword?: string;
    createdAfter?: string;
    priorityItems?: Array<object>;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateWorkProductDefinitionRequestParams {
    id: number;
    workProductDefinitionDto: WorkProductDefinitionDto;
}


@Injectable({
  providedIn: 'root'
})
export class WorkProductDefinitionControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Work Product Definition
     * Create New Version of Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewVersion(requestParameters: CreateNewVersionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public createNewVersion(requestParameters: CreateNewVersionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public createNewVersion(requestParameters: CreateNewVersionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public createNewVersion(requestParameters: CreateNewVersionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling createNewVersion.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions/${encodeURIComponent(String(id))}/new-version`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Create Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createWorkProductDefinition(requestParameters: CreateWorkProductDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public createWorkProductDefinition(requestParameters: CreateWorkProductDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public createWorkProductDefinition(requestParameters: CreateWorkProductDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public createWorkProductDefinition(requestParameters: CreateWorkProductDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const workProductDefinitionDto = requestParameters.workProductDefinitionDto;
        if (workProductDefinitionDto === null || workProductDefinitionDto === undefined) {
            throw new Error('Required parameter workProductDefinitionDto was null or undefined when calling createWorkProductDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions`,
            workProductDefinitionDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Delete Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteWorkProductDefinition(requestParameters: DeleteWorkProductDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<any>;
    public deleteWorkProductDefinition(requestParameters: DeleteWorkProductDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<any>>;
    public deleteWorkProductDefinition(requestParameters: DeleteWorkProductDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<any>>;
    public deleteWorkProductDefinition(requestParameters: DeleteWorkProductDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteWorkProductDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/work-product-definitions/${encodeURIComponent(String(id))}/delete`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Get All Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllWorkProductDefinition(requestParameters: GetAllWorkProductDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<WorkProductDefinitionPojo>>;
    public getAllWorkProductDefinition(requestParameters: GetAllWorkProductDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<WorkProductDefinitionPojo>>>;
    public getAllWorkProductDefinition(requestParameters: GetAllWorkProductDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<WorkProductDefinitionPojo>>>;
    public getAllWorkProductDefinition(requestParameters: GetAllWorkProductDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const approvalStatus = requestParameters.approvalStatus;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (approvalStatus !== undefined && approvalStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>approvalStatus, 'approvalStatus');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<WorkProductDefinitionPojo>>(`${this.configuration.basePath}/work-product-definitions/get-all-work-product-definition`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Get Work Product Definition by Process Definition Id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutputWorkProductDefinitionByProcessDefinitionId(requestParameters: GetOutputWorkProductDefinitionByProcessDefinitionIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public getOutputWorkProductDefinitionByProcessDefinitionId(requestParameters: GetOutputWorkProductDefinitionByProcessDefinitionIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public getOutputWorkProductDefinitionByProcessDefinitionId(requestParameters: GetOutputWorkProductDefinitionByProcessDefinitionIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public getOutputWorkProductDefinitionByProcessDefinitionId(requestParameters: GetOutputWorkProductDefinitionByProcessDefinitionIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOutputWorkProductDefinitionByProcessDefinitionId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions/process-definition/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Get Work Product Definition by Process Execution Instance Id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOutputWorkProductDefinitionByProcessExecutionInstanceId(requestParameters: GetOutputWorkProductDefinitionByProcessExecutionInstanceIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public getOutputWorkProductDefinitionByProcessExecutionInstanceId(requestParameters: GetOutputWorkProductDefinitionByProcessExecutionInstanceIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public getOutputWorkProductDefinitionByProcessExecutionInstanceId(requestParameters: GetOutputWorkProductDefinitionByProcessExecutionInstanceIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public getOutputWorkProductDefinitionByProcessExecutionInstanceId(requestParameters: GetOutputWorkProductDefinitionByProcessExecutionInstanceIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getOutputWorkProductDefinitionByProcessExecutionInstanceId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions/process-execution-instance/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Get Work Product Definition by Id
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getWorkProductDefinitionById(requestParameters: GetWorkProductDefinitionByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public getWorkProductDefinitionById(requestParameters: GetWorkProductDefinitionByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public getWorkProductDefinitionById(requestParameters: GetWorkProductDefinitionByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public getWorkProductDefinitionById(requestParameters: GetWorkProductDefinitionByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getWorkProductDefinitionById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Save Work Product Section Definition Details
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveWorkProductSectionDefinitionDetails(requestParameters: SaveWorkProductSectionDefinitionDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public saveWorkProductSectionDefinitionDetails(requestParameters: SaveWorkProductSectionDefinitionDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public saveWorkProductSectionDefinitionDetails(requestParameters: SaveWorkProductSectionDefinitionDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public saveWorkProductSectionDefinitionDetails(requestParameters: SaveWorkProductSectionDefinitionDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling saveWorkProductSectionDefinitionDetails.');
        }
        const requestApproval = requestParameters.requestApproval;
        if (requestApproval === null || requestApproval === undefined) {
            throw new Error('Required parameter requestApproval was null or undefined when calling saveWorkProductSectionDefinitionDetails.');
        }
        const workProductSectionDefinitionDetailDto = requestParameters.workProductSectionDefinitionDetailDto;
        if (workProductSectionDefinitionDetailDto === null || workProductSectionDefinitionDetailDto === undefined) {
            throw new Error('Required parameter workProductSectionDefinitionDetailDto was null or undefined when calling saveWorkProductSectionDefinitionDetails.');
        }
        const selectedApprover = requestParameters.selectedApprover;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (requestApproval !== undefined && requestApproval !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>requestApproval, 'requestApproval');
        }
        if (selectedApprover !== undefined && selectedApprover !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>selectedApprover, 'selectedApprover');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/work-product-definitions/${encodeURIComponent(String(id))}/save-section-definition-details`,
            workProductSectionDefinitionDetailDto,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Filter Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchWorkProductDefinition(requestParameters: SearchWorkProductDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsWorkProductDefinitionPojo>;
    public searchWorkProductDefinition(requestParameters: SearchWorkProductDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsWorkProductDefinitionPojo>>;
    public searchWorkProductDefinition(requestParameters: SearchWorkProductDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsWorkProductDefinitionPojo>>;
    public searchWorkProductDefinition(requestParameters: SearchWorkProductDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const reviewedAt = requestParameters.reviewedAt;
        const reviewer = requestParameters.reviewer;
        const footerInfo = requestParameters.footerInfo;
        const versionNumber = requestParameters.versionNumber;
        const reasonForReview = requestParameters.reasonForReview;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const versionNumberDisplayName = requestParameters.versionNumberDisplayName;
        const createdBy = requestParameters.createdBy;
        const previousVersion = requestParameters.previousVersion;
        const name = requestParameters.name;
        const workProductDocumentStyle = requestParameters.workProductDocumentStyle;
        const logo = requestParameters.logo;
        const reviewStatus = requestParameters.reviewStatus;
        const id = requestParameters.id;
        const complete = requestParameters.complete;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (reviewedAt !== undefined && reviewedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewedAt, 'reviewedAt');
        }
        if (reviewer !== undefined && reviewer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewer, 'reviewer');
        }
        if (footerInfo !== undefined && footerInfo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>footerInfo, 'footerInfo');
        }
        if (versionNumber !== undefined && versionNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>versionNumber, 'versionNumber');
        }
        if (reasonForReview !== undefined && reasonForReview !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForReview, 'reasonForReview');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (versionNumberDisplayName !== undefined && versionNumberDisplayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>versionNumberDisplayName, 'versionNumberDisplayName');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (previousVersion !== undefined && previousVersion !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>previousVersion, 'previousVersion');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (workProductDocumentStyle !== undefined && workProductDocumentStyle !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>workProductDocumentStyle, 'workProductDocumentStyle');
        }
        if (logo !== undefined && logo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>logo, 'logo');
        }
        if (reviewStatus !== undefined && reviewStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reviewStatus, 'reviewStatus');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (complete !== undefined && complete !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>complete, 'complete');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsWorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Work Product Definition
     * Update Work Product Definition
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateWorkProductDefinition(requestParameters: UpdateWorkProductDefinitionRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<WorkProductDefinitionPojo>;
    public updateWorkProductDefinition(requestParameters: UpdateWorkProductDefinitionRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<WorkProductDefinitionPojo>>;
    public updateWorkProductDefinition(requestParameters: UpdateWorkProductDefinitionRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<WorkProductDefinitionPojo>>;
    public updateWorkProductDefinition(requestParameters: UpdateWorkProductDefinitionRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateWorkProductDefinition.');
        }
        const workProductDefinitionDto = requestParameters.workProductDefinitionDto;
        if (workProductDefinitionDto === null || workProductDefinitionDto === undefined) {
            throw new Error('Required parameter workProductDefinitionDto was null or undefined when calling updateWorkProductDefinition.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<WorkProductDefinitionPojo>(`${this.configuration.basePath}/work-product-definitions/${encodeURIComponent(String(id))}/edit`,
            workProductDefinitionDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
