/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';


export interface PlayerCommitments { 
    player?: NameIdPojo;
    commitments?: Array<number>;
    status?: PlayerCommitments.StatusEnum;
}
export namespace PlayerCommitments {
    export type StatusEnum = 'COMPLETED' | 'NOT_COMPLETED' | 'PRODUCTION_AUTHORIZATION' | 'RELEASED' | 'EXECUTED' | 'PENDING_REVIEW' | 'NOT_STARTED';
    export const StatusEnum = {
        Completed: 'COMPLETED' as StatusEnum,
        NotCompleted: 'NOT_COMPLETED' as StatusEnum,
        ProductionAuthorization: 'PRODUCTION_AUTHORIZATION' as StatusEnum,
        Released: 'RELEASED' as StatusEnum,
        Executed: 'EXECUTED' as StatusEnum,
        PendingReview: 'PENDING_REVIEW' as StatusEnum,
        NotStarted: 'NOT_STARTED' as StatusEnum
    };
}


