import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {LottieModule} from 'ngx-lottie';
import {WebcamModule} from 'ngx-webcam';
import {TranslateModule} from '@ngx-translate/core';
import {lottiePlayerFactory} from '../app.module';
import {FullPageLoaderComponent} from './components/loaders/full-page-loader/full-page-loader.component';
import {UnderscoreToSpacePipe} from './pipes/underscore-to-space.pipe';
import {FileSvgComponent} from './components/svg/file-svg/file-svg.component';
import {AuthLinkDirective} from './directives/auth-link/auth-link.directive';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {NoDataSvComponent} from './components/svg/no-data-sv/no-data-sv.component';
import {FilterPreviewComponent} from './components/filter-preview/filter-preview.component';
import {CamelCaseToSpacePipe} from './pipes/camel-case-to-space.pipe';
import {FileUrlPipe} from './pipes/file-url.pipe';
import {ConfirmDialogComponent} from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {DisapprovalDialogComponent} from './components/dialogs/disapproval-dialog/disapproval-dialog.component';
import {MinTwoDigitsPipePipe} from './pipes/min-two-digits-pipe.pipe';
import {AmountPipe} from './pipes/amount.pipe';
import {TotalRecordsComponent} from './components/total-records/total-records.component';
import {PageSizeComponent} from './components/page-size/page-size.component';
import {TotalAmountComponent} from './components/total-amount/total-amount.component';
import {NairaSignDirective} from './directives/naira-sign/naira-sign.directive';
import {AccountSwitcherComponent} from './components/account-switcher/account-switcher.component';
import {SuccessDialogComponent} from './components/success-dialog/success-dialog.component';
import {LoaderComponent} from './components/loaders/loader/loader.component';
import {EmComponent} from './components/em/em.component';
import {DatetimePipe} from './pipes/datetime.pipe';
import {TimeOnlyPipe} from './pipes/timeonly';
import {DateOnlyPipe} from './pipes/dateonly.pipe';
import {MaskPipe} from './pipes/mask.pipe';
import {NairaPipe} from './pipes/naira.pipe';
import {FaceCaptureDialogComponent} from './components/face-capture-dialog/face-capture-dialog.component';
import {ErrorDialogComponent} from './components/dialogs/error-dialog/error-dialog.component';
import {AlertMessageComponent} from './alert-message/alert-message.component';
import {ButtonWithLoaderComponent} from './button-with-loader/button-with-loader.component';
import {FileUploadComponent} from "./components/file-upload/file-upload.component";
import {ImageCropperComponent} from "./components/image-cropper/image-cropper.component";
import {LoadingComponent} from "./components/loaders/loading/loading.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {ModalModule} from "ngx-bootstrap/modal";
import {DragNDropDirective} from "./directives/drag-n-drop.directive";
import {ImgComponent} from "./components/img/img.component";
import {SearchTicketModalComponent} from './components/dialogs/search-ticket-modal/search-ticket-modal.component';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {MaterialModule} from "./material/material.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {TicketLifecycleDirective} from "./directives/ticket-lifecycle.directive";
import {ReadMoreComponent} from './components/read-more/read-more.component';
import {ConfirmComponent} from './components/dialogs/confirm/confirm.component';
import {EmptyListComponent} from "./components/empty-list/empty-list.component";
import {RequirementSelectorComponent} from './components/requirement-selector/requirement-selector.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {RouterModule} from "@angular/router";
import {
    RequirementSelectorDialogComponent
} from './components/dialogs/requirement-selector-dialog/requirement-selector-dialog.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TruncatePipe} from "./pipes/truncate.pipe";
import { ModuleSelectorComponent } from './components/module-selector/module-selector.component';
import { ModuleSelectorDialogComponent } from './components/dialogs/module-selector-dialog/module-selector-dialog.component';
import { MappedRequirementsComponent } from './components/mapped-requirements/mapped-requirements.component';
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {
    PlatformSelectionDialogComponent
} from "./components/dialogs/platform-selection-dialog/platform-selection-dialog.component";
import { CustomerValueSelectorDialogComponent } from './components/dialogs/customer-value-selector-dialog/customer-value-selector-dialog.component';
import { CustomerValueSelectorComponent } from './components/customer-value-selector/customer-value-selector.component';
import {AttachmentComponent} from "../pages/intranet/requests/components/attachment/attachment.component";
import {CharacterLimitComponent} from "./components/character-limit/character-limit.component";
import { PlatformSwitcherComponent } from './components/dialogs/platform-switcher/platform-switcher.component';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {
    PlatformCardComponent
} from "../pages/intranet/master-records/platforms/components/platform-card/platform-card.component";
import {
    PlatformSwitcherButtonComponent
} from "../pages/intranet/master-records/platforms/components/platform-switcher-button/platform-switcher-button.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {IdleConfirmDialogComponent} from "./components/idle-confirm-dialog/idle-confirm-dialog.component";
import {CountdownPipe} from "./pipes/count-down.pipe";
import { TimeFromLocalTimePipe } from './pipes/time-from-local-time.pipe';
import { InputOverInputComponent } from './components/input-over-input/input-over-input.component';
import {ExecutionMenuComponent} from "../pages/intranet/execution/components/execution-menu/execution-menu.component";
import { ComplianceTrackerComponent } from './components/compliance-tracker/compliance-tracker.component';
import { ProductionUnitSelectorComponent } from './components/production-unit-selector/production-unit-selector.component';
import {UploadExcelFileComponent} from "./components/upload-excel-file/upload-excel-file.component";
import {ExcelListPreviewComponent} from "./components/excel-list-preview/excel-list-preview.component";
import { TimerComponent } from './components/timer/timer.component';
import {
    ModuleCardComponent
} from "../pages/intranet/master-records/modules/components/module-card/module-card.component";
import { ProgressFillDirective } from './components/progress-fill.directive';
import {CommaSeparatedPipe} from "./pipes/comma-seperated.pipe";
import {FormErrorComponent} from "./forms/form-error/form-error.component";
import {NoteViewerComponent} from "./components/note-viewer/note-viewer.component";
import { PreviousNextButtonComponent } from './components/previous-next-button/previous-next-button.component';
import { StopWatchPipe } from './pipes/stop-watch.pipe';
import { StopWatchComponent } from './components/stop-watch/stop-watch.component';
import { ViewPlayerComponent } from './components/view-player/view-player.component';
import { ViewPlayerDialogComponent } from './components/dialogs/view-player-dialog/view-player-dialog.component';
import { PlayerPhotoGalleryComponent } from './components/player-photo-gallery/player-photo-gallery.component';
import {
    BulkUploadResultDialogComponent
} from "./components/bulk-upload-result-dialog/bulk-upload-result-dialog.component";
import {
    UatKeyResultUpdateStatusSearchComponent
} from "../pages/intranet/execution/wig/components/uat-key-result-update-status-search/uat-key-result-update-status-search.component";
import { ProductionUnitSelectorDialogComponent } from './components/dialogs/production-unit-selector-dialog/production-unit-selector-dialog.component';
import {RescheduleUatDialogComponent} from "./components/reschedule-uat-dialog/reschedule-uat-dialog.component";
import {PageLengthComponent} from "./components/page-length/page-length.component";
import {FilePreviewComponent} from "./components/file-preview/file-preview.component";
import { UserStorySelectorComponent } from './components/user-story-selector/user-story-selector.component';
import {UserStoryModal} from "./components/user-story-modal/user-story-modal.component";
import {MatRadioModule} from "@angular/material/radio";
import { UserCardHeaderComponent } from './components/user-card-header/user-card-header.component';
import { CriteriaCountModalComponent } from './components/criteria-count-modal/criteria-count-modal.component';
import { UserStorySearchComponent } from './components/user-story-search/user-story-search.component';
import { ComplianceWorkflowTrackerDialogComponent } from './components/dialogs/compliance-workflow-tracker-dialog/compliance-workflow-tracker-dialog.component';
import { ComplianceWorkflowTrackerComponent } from './components/compliance-workflow-tracker/compliance-workflow-tracker.component';
import { AcceptanceCriteriaSelectorComponent } from './components/acceptance-criteria-selector/acceptance-criteria-selector.component';
import { ComplianceDisapprovalDialogComponent } from './components/dialogs/compliance-disapproval-dialog/compliance-disapproval-dialog.component';
import { ComplianceDisapprovalComponent } from './components/compliance-disapproval/compliance-disapproval.component';
import {
    CodingStandardSearchComponent
} from "../pages/intranet/process/components/coding-standard-search/coding-standard-search.component";
import { ProductionUnitLifecycleComponent } from './components/dialogs/production-unit-lifecycle-tracker/production-unit-lifecycle.component';
import { BaseAlertComponent } from './base-alert/base-alert.component';
import { DateWithoutYearPipe } from './pipes/date-without-year.pipe';
import { IconSelectionComponent } from './icon-selection/icon-selection.component';
import { FlowChartComponent } from './components/flow-chart/flow-chart.component';
import {SelectDropdownStateDirective} from "./directives/select-dropdown-state.directive";
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { ViewWatchTimeComponent } from './components/view-watch-time/view-watch-time.component';


@NgModule({
    declarations: [
        FullPageLoaderComponent,
        FileSvgComponent,
        NoDataSvComponent,
        UnderscoreToSpacePipe,
        AuthLinkDirective,
        EnumToArrayPipe,
        FaceCaptureDialogComponent,
        FilterPreviewComponent,
        CamelCaseToSpacePipe,
        FileUrlPipe,
        ConfirmDialogComponent,
        DisapprovalDialogComponent,
        MinTwoDigitsPipePipe,
        AmountPipe,
        TotalRecordsComponent,
        PageSizeComponent,
        NairaSignDirective,
        TotalAmountComponent,
        AccountSwitcherComponent,
        SuccessDialogComponent,
        LoaderComponent,
        EmComponent,
        DatetimePipe,
        TimeOnlyPipe,
        DateOnlyPipe,
        DateWithoutYearPipe,
        MaskPipe,
        NairaPipe,
        ErrorDialogComponent,
        AlertMessageComponent,
        ButtonWithLoaderComponent,
        FileUploadComponent,
        ImageCropperComponent,
        LoadingComponent,
        DragNDropDirective,
        ImgComponent,
        SearchTicketModalComponent,
        TicketLifecycleDirective,
        ReadMoreComponent,
        ConfirmComponent,
        EmptyListComponent,
        PlatformSelectionDialogComponent,
        RequirementSelectorComponent,
        RequirementSelectorDialogComponent,
        TruncatePipe,
        ModuleSelectorComponent,
        ModuleSelectorDialogComponent,
        MappedRequirementsComponent,
        CustomerValueSelectorDialogComponent,
        CustomerValueSelectorComponent,
        AttachmentComponent,
        PlatformSwitcherComponent,
        PlatformCardComponent,
        PlatformSwitcherButtonComponent,
        IdleConfirmDialogComponent,
        CountdownPipe,
        TimeFromLocalTimePipe,
        InputOverInputComponent,
        ExecutionMenuComponent,
        ComplianceTrackerComponent,
        ProductionUnitSelectorComponent,
        UploadExcelFileComponent,
        ExcelListPreviewComponent,
        TimerComponent,
        ModuleCardComponent,
        FormErrorComponent,
        ProgressFillDirective,
        CommaSeparatedPipe,
        NoteViewerComponent,
        PreviousNextButtonComponent,
        StopWatchPipe,
        StopWatchComponent,
        ViewPlayerComponent,
        ViewPlayerDialogComponent,
        PlayerPhotoGalleryComponent,
        BulkUploadResultDialogComponent,
        UatKeyResultUpdateStatusSearchComponent,
        ProductionUnitSelectorDialogComponent,
        RescheduleUatDialogComponent,
        PageLengthComponent,
        FilePreviewComponent,
        UserStorySelectorComponent,
        UserStoryModal,
        UserCardHeaderComponent,
        CriteriaCountModalComponent,
        UserStorySearchComponent,
        ComplianceWorkflowTrackerDialogComponent,
        ComplianceWorkflowTrackerComponent,
        AcceptanceCriteriaSelectorComponent,
        ComplianceDisapprovalDialogComponent,
        ComplianceDisapprovalComponent,
        CodingStandardSearchComponent,
        ProductionUnitLifecycleComponent,
        BaseAlertComponent,
        DateWithoutYearPipe,
        IconSelectionComponent,
        DateWithoutYearPipe,
        FlowChartComponent,
        CharacterLimitComponent,
        SelectDropdownStateDirective,
        TimeFormatPipe,
        ViewWatchTimeComponent
    ],
    providers: [DatePipe],
    imports: [
        CommonModule,
        NgxDropzoneModule,
        FormsModule,
        WebcamModule,
        ReactiveFormsModule,
        LottieModule.forRoot({player: lottiePlayerFactory}),
        TranslateModule,
        ImageCropperModule,
        ModalModule.forRoot(),
        NgxIntlTelInputModule,
        MaterialModule,
        ScrollingModule,
        MatMenuModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,
        PaginationModule,
        RouterModule,
        MatCheckboxModule,
        BsDatepickerModule,
        NgMultiSelectDropDownModule,
        TooltipModule,
        MatRadioModule
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        AuthLinkDirective,
        ConfirmDialogComponent,
        FileSvgComponent,
        UnderscoreToSpacePipe,
        EnumToArrayPipe,
        FilterPreviewComponent,
        FileUrlPipe,
        MinTwoDigitsPipePipe,
        AmountPipe,
        FaceCaptureDialogComponent,
        TotalRecordsComponent,
        PageSizeComponent,
        NairaSignDirective,
        TotalAmountComponent,
        CamelCaseToSpacePipe,
        AccountSwitcherComponent,
        LoaderComponent,
        EmComponent,
        DatetimePipe,
        TimeOnlyPipe,
        DateOnlyPipe,
        DateWithoutYearPipe,
        SuccessDialogComponent,
        NairaPipe,
        AlertMessageComponent,
        ButtonWithLoaderComponent,
        FileUploadComponent,
        ImageCropperComponent,
        LoadingComponent,
        DragNDropDirective,
        ImgComponent,
        MaterialModule,
        ScrollingModule,
        TicketLifecycleDirective,
        ReadMoreComponent,
        EmptyListComponent,
        TruncatePipe,
        RequirementSelectorComponent,
        MappedRequirementsComponent,
        AttachmentComponent,
        PlatformSwitcherButtonComponent,
        CountdownPipe,
        TimeFromLocalTimePipe,
        InputOverInputComponent,
        ExecutionMenuComponent,
        ComplianceTrackerComponent,
        IdleConfirmDialogComponent,
        CustomerValueSelectorComponent,
        ProductionUnitSelectorComponent,
        UploadExcelFileComponent,
        ExcelListPreviewComponent,
        TimerComponent,
        ModuleCardComponent,
        FormErrorComponent,
        CommaSeparatedPipe,
        StopWatchComponent,
        ProgressFillDirective,
        NoteViewerComponent,
        PreviousNextButtonComponent,
        PlayerPhotoGalleryComponent,
        BulkUploadResultDialogComponent,
        UatKeyResultUpdateStatusSearchComponent,
        PageLengthComponent,
        FilePreviewComponent,
        UserStorySelectorComponent,
        UserCardHeaderComponent,
        UserStorySearchComponent,
        AcceptanceCriteriaSelectorComponent,
        BaseAlertComponent,
        CodingStandardSearchComponent,
        FullPageLoaderComponent,
        FlowChartComponent,
        CharacterLimitComponent,
        SelectDropdownStateDirective,
        TimeFormatPipe,
        ViewWatchTimeComponent
    ],
    schemas:[ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
