import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

    transform(value: number, unit = '', ...args: unknown[]): string {
        if (!value || value < 0) return '00';

        const hours = Math.floor(value / 60);
        const minutes = Math.floor(value % 60);
        const seconds = Math.floor((value * 60) % 60);

        switch (unit) {
            case 'seconds':
                return `${seconds}`;
            case 'minutes':
                return `${minutes}`;
            case 'hours':
                return `${hours}`;
            default:
                return `${hours} Hours, ${minutes} min, ${seconds} Seconds`;
        }
    }

}
