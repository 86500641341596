/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModuleCommitmentDto } from './module-commitment-dto';
import { TicketCommitmentDto } from './ticket-commitment-dto';
import { ActivityCommitmentDto } from './activity-commitment-dto';
import { DelegationCommitmentDto } from './delegation-commitment-dto';
import { CustomerValueCommitmentDto } from './customer-value-commitment-dto';
import { ProcessActionDto } from './process-action-dto';
import { ProductionUnitCommitmentDto } from './production-unit-commitment-dto';


export interface LeadMeasureCommitmentV2Dto { 
    departmentId?: number;
    processModelId?: number;
    processDefinitionId: number;
    workProductInputId: number;
    processActionDtos?: Array<ProcessActionDto>;
    executedDomainEntityType: LeadMeasureCommitmentV2Dto.ExecutedDomainEntityTypeEnum;
    effortType?: LeadMeasureCommitmentV2Dto.EffortTypeEnum;
    cadenceEventId?: number;
    wigId?: number;
    originalLeadMeasureCommitmentId?: number;
    platformId?: number;
    moduleCommitmentDto?: ModuleCommitmentDto;
    customerValueCommitmentDto?: CustomerValueCommitmentDto;
    productionUnitCommitmentDto?: ProductionUnitCommitmentDto;
    delegationCommitmentDto?: DelegationCommitmentDto;
    ticketCommitmentDto?: TicketCommitmentDto;
    activityCommitmentDto?: ActivityCommitmentDto;
    leadMeasureCommitmentCategory: LeadMeasureCommitmentV2Dto.LeadMeasureCommitmentCategoryEnum;
}
export namespace LeadMeasureCommitmentV2Dto {
    export type ExecutedDomainEntityTypeEnum = 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'TICKET' | 'ACTIVITY';
    export const ExecutedDomainEntityTypeEnum = {
        Module: 'MODULE' as ExecutedDomainEntityTypeEnum,
        CustomerValue: 'CUSTOMER_VALUE' as ExecutedDomainEntityTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as ExecutedDomainEntityTypeEnum,
        Delegation: 'DELEGATION' as ExecutedDomainEntityTypeEnum,
        Ticket: 'TICKET' as ExecutedDomainEntityTypeEnum,
        Activity: 'ACTIVITY' as ExecutedDomainEntityTypeEnum
    };
    export type EffortTypeEnum = 'NEW' | 'UPDATE';
    export const EffortTypeEnum = {
        New: 'NEW' as EffortTypeEnum,
        Update: 'UPDATE' as EffortTypeEnum
    };
    export type LeadMeasureCommitmentCategoryEnum = 'WIG' | 'WHIRLWIND';
    export const LeadMeasureCommitmentCategoryEnum = {
        Wig: 'WIG' as LeadMeasureCommitmentCategoryEnum,
        Whirlwind: 'WHIRLWIND' as LeadMeasureCommitmentCategoryEnum
    };
}


