/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';


export interface ProcessActionExecutionInstancePojo { 
    id?: number;
    createdAt?: string;
    startTime?: string;
    endTime?: string;
    executionStatus?: ProcessActionExecutionInstancePojo.ExecutionStatusEnum;
    createdBy?: NameIdPojo;
    leadMeasure?: NameIdPojo;
    commitmentTarget?: string;
    player?: NameIdPojo;
    processActionDefinition?: NameIdPojo;
}
export namespace ProcessActionExecutionInstancePojo {
    export type ExecutionStatusEnum = 'NOT_STARTED' | 'STARTED' | 'SUSPENDED' | 'TERMINATED' | 'FINISHED';
    export const ExecutionStatusEnum = {
        NotStarted: 'NOT_STARTED' as ExecutionStatusEnum,
        Started: 'STARTED' as ExecutionStatusEnum,
        Suspended: 'SUSPENDED' as ExecutionStatusEnum,
        Terminated: 'TERMINATED' as ExecutionStatusEnum,
        Finished: 'FINISHED' as ExecutionStatusEnum
    };
}


