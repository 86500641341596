export enum PermissionType {
  CREATE_USER = 'CREATE_USER',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  SCHEME_REVIEWER = 'SCHEME_REVIEWER',
  VIEW_USER = 'VIEW_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  VIEW_LOG = 'VIEW_LOG',
  CREATE_WORK_PRODUCT_DEFINITION = 'CREATE_WORK_PRODUCT_DEFINITION',
  DELETE_WORK_PRODUCT_DEFINITION = 'DELETE_WORK_PRODUCT_DEFINITION',
  UPDATE_WORK_PRODUCT_DEFINITION = 'UPDATE_WORK_PRODUCT_DEFINITION',
  CREATE_WORK_PRODUCT = 'CREATE_WORK_PRODUCT',
  UPDATE_WORK_PRODUCT = 'UPDATE_WORK_PRODUCT',
  VIEW_WORK_PRODUCT = 'VIEW_WORK_PRODUCT',
  CREATE_BUSINESS_REQUIREMENT = 'CREATE_BUSINESS_REQUIREMENT',
  UPDATE_BUSINESS_REQUIREMENT = 'UPDATE_BUSINESS_REQUIREMENT'
}
