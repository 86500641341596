/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BreachDto { 
    categoryId: number;
    processStageCodes: Array<string>;
    workflowTrackingId?: string;
    financialCostAlgorithmCode?: string;
    financialCostBreachCategoryCode?: string;
    note?: string;
    loggedForUserId?: number;
    learning?: string;
    pendingTraining?: boolean;
    trainingTakenAt?: string;
    auto?: boolean;
    platformId?: number;
    logFinancialCost?: boolean;
}

