/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ExecutionProcessDefinitionPojo } from './execution-process-definition-pojo';
import { NameIdPojo } from './name-id-pojo';
import { MoneyPojo } from './money-pojo';
import { PlayerProcessCostPojo } from './player-process-cost-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface ProcessExecutionInstancePojo { 
    id?: number;
    createdBy?: NameIdPojo;
    createdAt?: string;
    wig?: NameIdPojo;
    endTime?: string;
    startTime?: string;
    players?: Array<PortalUserPojo>;
    inputWorkProducts?: Array<NameIdPojo>;
    outputWorkProduct?: NameIdPojo;
    executionStatus?: ProcessExecutionInstancePojo.ExecutionStatusEnum;
    processDefinition?: ExecutionProcessDefinitionPojo;
    identifier?: string;
    startedBy?: NameIdPojo;
    startedAt?: string;
    cycleTimeInMinutes?: number;
    timeVarianceInMinutes?: number;
    numberOfErrors?: number;
    totalDefectTimeInMinutes?: number;
    totalWaitingTimeInMinutes?: number;
    financialCost?: MoneyPojo;
    financialCostsPerPlayer?: Array<PlayerProcessCostPojo>;
}
export namespace ProcessExecutionInstancePojo {
    export type ExecutionStatusEnum = 'NOT_STARTED' | 'STARTED' | 'SUSPENDED' | 'TERMINATED' | 'FINISHED';
    export const ExecutionStatusEnum = {
        NotStarted: 'NOT_STARTED' as ExecutionStatusEnum,
        Started: 'STARTED' as ExecutionStatusEnum,
        Suspended: 'SUSPENDED' as ExecutionStatusEnum,
        Terminated: 'TERMINATED' as ExecutionStatusEnum,
        Finished: 'FINISHED' as ExecutionStatusEnum
    };
}


