/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SoftwareModulePojo } from './software-module-pojo';
import { WigPojo } from './wig-pojo';
import { LagMeasurePojo } from './lag-measure-pojo';
import { LagPojo } from './lag-pojo';
import { NameIdPojo } from './name-id-pojo';
import { PortalAccountTypeRole } from './portal-account-type-role';
import { CreatedByPojo } from './created-by-pojo';
import { BusinessRequirementPojo } from './business-requirement-pojo';
import { UserStoryPojo } from './user-story-pojo';


export interface CustomerValuePojo { 
    id?: number;
    identifier?: string;
    identifierDisplayName?: string;
    description?: string;
    name?: string;
    platformPojo?: NameIdPojo;
    createdAt?: string;
    definitionStatus?: CustomerValuePojo.DefinitionStatusEnum;
    businessRequirements?: Array<BusinessRequirementPojo>;
    softwareModules?: Array<SoftwareModulePojo>;
    dueAt?: string;
    completionStatus?: CustomerValuePojo.CompletionStatusEnum;
    plannedAcceptanceTime?: string;
    wig?: WigPojo;
    type?: CustomerValuePojo.TypeEnum;
    lagMeasures?: Array<LagMeasurePojo>;
    score?: number;
    activityCount?: number;
    lagPojo?: LagPojo;
    authorisedRoles?: Array<PortalAccountTypeRole>;
    userStoryPojos?: Array<UserStoryPojo>;
    reviewStatusConstant?: CustomerValuePojo.ReviewStatusConstantEnum;
    reviewedAt?: string;
    reviewer?: NameIdPojo;
    reasonForReview?: string;
    isUndone?: boolean;
    createdByPojo?: CreatedByPojo;
}
export namespace CustomerValuePojo {
    export type DefinitionStatusEnum = 'DEFINED' | 'PARTIALLY_DEFINED' | 'NOT_DEFINED';
    export const DefinitionStatusEnum = {
        Defined: 'DEFINED' as DefinitionStatusEnum,
        PartiallyDefined: 'PARTIALLY_DEFINED' as DefinitionStatusEnum,
        NotDefined: 'NOT_DEFINED' as DefinitionStatusEnum
    };
    export type CompletionStatusEnum = 'COMPLETED' | 'NOT_COMPLETED';
    export const CompletionStatusEnum = {
        Completed: 'COMPLETED' as CompletionStatusEnum,
        NotCompleted: 'NOT_COMPLETED' as CompletionStatusEnum
    };
    export type TypeEnum = 'PERFORMANCE' | 'PRODUCTIVITY';
    export const TypeEnum = {
        Performance: 'PERFORMANCE' as TypeEnum,
        Productivity: 'PRODUCTIVITY' as TypeEnum
    };
    export type ReviewStatusConstantEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusConstantEnum = {
        Approved: 'APPROVED' as ReviewStatusConstantEnum,
        Pending: 'PENDING' as ReviewStatusConstantEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusConstantEnum
    };
}


