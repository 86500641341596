<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<div class="mt-2">
    <div class="row">
        <div class="col-md-4 d-flex align-items-center">
            <label class="form-label mb-0" [class.required]="required">{{ label }}</label>
        </div>
        <div class="col-md-8">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <div class="mb-1 p-3 searchx bg-white row align-items-end row-gap-3">
                    <div class="col-md-6 text-end">
                        <input type="text" formControlName="keyword" id="keyword"
                               class="form-control"
                               placeholder="Search Keyword">
                    </div>
                    <div class="col-md-6 text-end">
                        <select id="classification" class="form-select">
                            <option value="''" selected>select classification</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="searchManager.totalCount > 0 " class="row"
         [ngClass]="{'disabled-table': searchManager.working}">
        <div class="d-md-flex flex-wrap align-items-center justify-content-between">
            <div class="col-md-4">
                <app-total-records [searchManager]="searchManager"></app-total-records>
            </div>
            <div class="col-md-4x justify-content-end">
                <page-size
                        *ngIf="searchManager.totalCount > 0"
                        [searchManager]="searchManager"
                ></page-size>
            </div>
        </div>
    </div>
    <div class="p-d3 px-2 bg-white background mdx-3">

        <div class="d-flex justify-content-between flex-wrap p-1 bg-white ms-3 mt-2"
             style="border-radius: 5px;">
            <div class="pos">
                <mat-checkbox *ngIf="false" color="primary" (change)="$event ? toggleAllRows() : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
                <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                <u class="fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
            </div>

            <button *ngIf="false" [disabled]="selected?.length == 0" class="btn btn-dark px-4 btn-lg mt-2">
                {{ callToActionText }}
            </button>
        </div>
        <hr>
        <div class="card " style="border: none;">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">S/N</th>
                            <th scope="col">Code</th>
                            <th scope="col">Name</th>
                            <th scope="col">Description</th>
                        </tr>
                        </thead>

                        <ng-container *ngIf="!searchManager._working; else stillLoading">
                            <ng-container *ngIf="searchManager.list.length > 0; else NoDataToDisplay">
                                <tbody>
                                <tr
                                        class="justify-content-center"
                                        *ngFor="let standard of searchManager.list; let i = index">
                                    <td>
                                        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                      (change)="$event ? selection.toggle(standard?.id) : null"
                                                      [checked]="selection.isSelected(standard.id)">
                                        </mat-checkbox>
                                    </td>
                                    <td>{{ i + searchManager.offset + 1 }}</td>
                                    <td>{{ standard?.code || '-' }}</td>
                                    <td>{{ standard?.name || '-' }}</td>
                                    <td>
                                        UI is responsive on mobile and desktop screens
                                    </td>
                                </tr>
                                </tbody>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
            </div>
        </div>

    </div>


    <div
            *ngIf="searchManager.totalCount > 10"
            class="row py-2"
            [ngClass]="{ 'disabled-table': searchManager.working }"
    >
        <div class="col-12 d-flex justify-content-end">
            <pagination
                    [(ngModel)]="searchManager.page"
                    [boundaryLinks]="true"
                    [itemsPerPage]="searchManager.itemsPerPage"
                    [maxSize]="maxPageSize"
                    [rotate]="true"
                    [totalItems]="searchManager.totalCount"
                    firstText="&laquo;"
                    lastText="&raquo;"
                    nextText="&rsaquo;"
                    previousText="&lsaquo;"
            ></pagination>
        </div>
    </div>

</div>


<ng-template #stillLoading>
    <tr>
        <td colspan="100%">
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #NoDataToDisplay>
    <tr>
        <td class="text-center" colspan="100%">NO DATA</td>
    </tr>
</ng-template>

<ng-template #tool let-view="view">
    <div class="">
        <div *ngFor=" let item of view?.productionUnits?.slice(0,2);">{{ item?.name }}</div>
    </div>
</ng-template>
