/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PlayerCommitments } from './player-commitments';
import { NameIdPojo } from './name-id-pojo';


export interface ActionMetaData { 
    roles?: Array<string>;
    baseRuntimeInMinutes?: number;
    executionStartedAt?: string;
    executionEndedAt?: string;
    executionStatus?: ActionMetaData.ExecutionStatusEnum;
    playerCommitments?: Array<PlayerCommitments>;
    disapprovalPath?: Array<NameIdPojo>;
}
export namespace ActionMetaData {
    export type ExecutionStatusEnum = 'NOT_STARTED' | 'STARTED' | 'SUSPENDED' | 'TERMINATED' | 'FINISHED';
    export const ExecutionStatusEnum = {
        NotStarted: 'NOT_STARTED' as ExecutionStatusEnum,
        Started: 'STARTED' as ExecutionStatusEnum,
        Suspended: 'SUSPENDED' as ExecutionStatusEnum,
        Terminated: 'TERMINATED' as ExecutionStatusEnum,
        Finished: 'FINISHED' as ExecutionStatusEnum
    };
}


