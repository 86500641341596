<div class="card rounded-3 h-100 border-0" style="background-color: #E8EAED">
    <div class="card-body">
        <div class="">
            <div class="card h-100 border-0  rounded-3">
                <div class=" card-body">
                    <div class="mb-3">
                        <div class=" fw-semibold">
                            Customer Value ID
                        </div>
                        <div class=" ">
                            {{ c5?.identifierDisplayName }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class=" fw-semibold">
                            C5 Description
                        </div>
                        <div class=" ">
                            {{ c5?.type == 'PRODUCTIVITY' ? c5?.name : (c5?.description || c5?.name) | lowercase }}
                            <span *ngIf="c5?.reviewStatusConstant == 'DISAPPROVED' && c5?.reasonForReview" [placement]="'right'"
                                  [tooltip]="c5?.reasonForReview">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                        </div>
                    </div>
                    
                    <div class=" float-end "  *ngIf="c5.softwareModules?.length > 0">
                        <div class="d-flex align-items-center justify-content-center"
                             [placement]="'right'"
                             [tooltip]="tooltipTemplate"
                             style="border-radius: 5px; background: #848588; flex-shrink: 0; height: 25px; width: 37px;">
                                                        <span class="iconify fs-5 text-white"
                                                              data-icon="ic:baseline-ac-unit"></span>
                            <div class=" text-white">{{ c5.softwareModules?.length || 0 }}</div>
                            <ng-template #tooltipTemplate >
                                <div class="fw-semibold">Mapped Module(s)</div>
                                <div *ngFor="let req of c5.softwareModules; let i = index">
                                    {{ i + 1 }}. {{ req.name }}
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
