/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { NameCodeIdPojo } from './name-code-id-pojo';
import { MoneyPojo } from './money-pojo';
import { ProcessLeadMeasureDefinitionPojo } from './process-lead-measure-definition-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { ProcessActionDefinitionPojo } from './process-action-definition-pojo';


export interface ProcessDefinitionPojo { 
    id?: number;
    createdAt?: string;
    portalAccountId?: number;
    createdBy?: NameIdPojo;
    processName?: string;
    identifier?: string;
    definitionScore?: number;
    name?: string;
    description?: string;
    code?: string;
    ordinal?: number;
    versionNumber?: number;
    versionNumberDisplayName?: string;
    iconName?: string;
    displayImage?: AttachmentPojo;
    processModel?: NameIdPojo;
    processOwner?: NameIdPojo;
    baselineRuntimeInMinutes?: number;
    processActionDefinitions?: Array<ProcessActionDefinitionPojo>;
    processLeadMeasureDefinitions?: Array<ProcessLeadMeasureDefinitionPojo>;
    inputWorkProductDefinitions?: Array<NameCodeIdPojo>;
    outputWorkProductDefinition?: NameCodeIdPojo;
    quantitativeProcessMeasures?: Array<NameIdPojo>;
    wasteMeasures?: Array<ProcessDefinitionPojo.WasteMeasuresEnum>;
    purposeAnnotations?: Array<ProcessDefinitionPojo.PurposeAnnotationsEnum>;
    reviewStatus?: ProcessDefinitionPojo.ReviewStatusEnum;
    approverRoles?: Array<NameIdPojo>;
    locked?: boolean;
    averageCycleTimeInMinutes?: number;
    numberOfErrors?: number;
    firstRuntime?: string;
    averageActualRuntimeInMinutes?: number;
    financialCost?: MoneyPojo;
    totalOutput?: number;
}
export namespace ProcessDefinitionPojo {
    export type WasteMeasuresEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteMeasuresEnum = {
        Time: 'TIME' as WasteMeasuresEnum,
        Inventory: 'INVENTORY' as WasteMeasuresEnum,
        Motion: 'MOTION' as WasteMeasuresEnum,
        Waiting: 'WAITING' as WasteMeasuresEnum,
        OverProduction: 'OVER_PRODUCTION' as WasteMeasuresEnum,
        OverProcessing: 'OVER_PROCESSING' as WasteMeasuresEnum,
        Defect: 'DEFECT' as WasteMeasuresEnum
    };
    export type PurposeAnnotationsEnum = 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    export const PurposeAnnotationsEnum = {
        UserAcceptance: 'USER_ACCEPTANCE' as PurposeAnnotationsEnum,
        QualityAssurance: 'QUALITY_ASSURANCE' as PurposeAnnotationsEnum,
        Documentation: 'DOCUMENTATION' as PurposeAnnotationsEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


