/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DelegationDto } from '../model/models';
import { DelegationPojo } from '../model/models';
import { DelegationStatusUpdateDto } from '../model/models';
import { QueryResultsDelegationPojo } from '../model/models';
import { RedelegationReasonPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateDelegationRequestParams {
    delegationDto: DelegationDto;
}

export interface DeleteDelegationRequestParams {
    id: number;
}

export interface EditDelegationRequestParams {
    delegationDto: DelegationDto;
}

export interface SearchDelegationRequestParams {
    customerValue?: string;
    redelegationReason?: string;
    customerValueId?: number;
    purpose?: string;
    delegator?: string;
    description?: string;
    blockerStrategy?: string;
    affectedCustomerName?: string;
    valueStatus?: 'VALUE_CAPTURED' | 'VALUE_NOT_CAPTURED' | 'PENDING' | 'RE_DELEGATED';
    remindAt?: string;
    reminderSent?: boolean;
    wigId?: number;
    dueAt?: string;
    delegate?: string;
    portalAccount?: string;
    createdAt?: string;
    delegateId?: number;
    createdBy?: string;
    affectedCustomerOrganisation?: string;
    topic?: string;
    comment?: string;
    id?: number;
    preDueTimeReminderSent?: boolean;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    offset?: number;
    affectedCustomer?: string;
    valueStatuses?: Array<object>;
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    priorityItems?: Array<object>;
    keyword?: string;
    orderColumn?: string;
    hasWig?: boolean;
    order?: 'ASC' | 'DESC';
}

export interface UpdateDelegationStatusRequestParams {
    delegationStatusUpdateDto: DelegationStatusUpdateDto;
}


@Injectable({
  providedIn: 'root'
})
export class DelegationControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDelegation(requestParameters: CreateDelegationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DelegationPojo>;
    public createDelegation(requestParameters: CreateDelegationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DelegationPojo>>;
    public createDelegation(requestParameters: CreateDelegationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DelegationPojo>>;
    public createDelegation(requestParameters: CreateDelegationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const delegationDto = requestParameters.delegationDto;
        if (delegationDto === null || delegationDto === undefined) {
            throw new Error('Required parameter delegationDto was null or undefined when calling createDelegation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DelegationPojo>(`${this.configuration.basePath}/delegations`,
            delegationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDelegation(requestParameters: DeleteDelegationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteDelegation(requestParameters: DeleteDelegationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteDelegation(requestParameters: DeleteDelegationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteDelegation(requestParameters: DeleteDelegationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDelegation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/delegations/${encodeURIComponent(String(id))}/delete`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public editDelegation(requestParameters: EditDelegationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DelegationPojo>;
    public editDelegation(requestParameters: EditDelegationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DelegationPojo>>;
    public editDelegation(requestParameters: EditDelegationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DelegationPojo>>;
    public editDelegation(requestParameters: EditDelegationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const delegationDto = requestParameters.delegationDto;
        if (delegationDto === null || delegationDto === undefined) {
            throw new Error('Required parameter delegationDto was null or undefined when calling editDelegation.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<DelegationPojo>(`${this.configuration.basePath}/delegations/edit`,
            delegationDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllReDelegationReasons(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<RedelegationReasonPojo>>;
    public getAllReDelegationReasons(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<RedelegationReasonPojo>>>;
    public getAllReDelegationReasons(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<RedelegationReasonPojo>>>;
    public getAllReDelegationReasons(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<RedelegationReasonPojo>>(`${this.configuration.basePath}/delegations/get-all-redelegation-reasons`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delegation
     * Filter Delegation
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDelegation(requestParameters: SearchDelegationRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsDelegationPojo>;
    public searchDelegation(requestParameters: SearchDelegationRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsDelegationPojo>>;
    public searchDelegation(requestParameters: SearchDelegationRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsDelegationPojo>>;
    public searchDelegation(requestParameters: SearchDelegationRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const customerValue = requestParameters.customerValue;
        const redelegationReason = requestParameters.redelegationReason;
        const customerValueId = requestParameters.customerValueId;
        const purpose = requestParameters.purpose;
        const delegator = requestParameters.delegator;
        const description = requestParameters.description;
        const blockerStrategy = requestParameters.blockerStrategy;
        const affectedCustomerName = requestParameters.affectedCustomerName;
        const valueStatus = requestParameters.valueStatus;
        const remindAt = requestParameters.remindAt;
        const reminderSent = requestParameters.reminderSent;
        const wigId = requestParameters.wigId;
        const dueAt = requestParameters.dueAt;
        const delegate = requestParameters.delegate;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const delegateId = requestParameters.delegateId;
        const createdBy = requestParameters.createdBy;
        const affectedCustomerOrganisation = requestParameters.affectedCustomerOrganisation;
        const topic = requestParameters.topic;
        const comment = requestParameters.comment;
        const id = requestParameters.id;
        const preDueTimeReminderSent = requestParameters.preDueTimeReminderSent;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const offset = requestParameters.offset;
        const affectedCustomer = requestParameters.affectedCustomer;
        const valueStatuses = requestParameters.valueStatuses;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const keyword = requestParameters.keyword;
        const orderColumn = requestParameters.orderColumn;
        const hasWig = requestParameters.hasWig;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (customerValue !== undefined && customerValue !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValue, 'customerValue');
        }
        if (redelegationReason !== undefined && redelegationReason !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>redelegationReason, 'redelegationReason');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (purpose !== undefined && purpose !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>purpose, 'purpose');
        }
        if (delegator !== undefined && delegator !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>delegator, 'delegator');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (blockerStrategy !== undefined && blockerStrategy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>blockerStrategy, 'blockerStrategy');
        }
        if (affectedCustomerName !== undefined && affectedCustomerName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedCustomerName, 'affectedCustomerName');
        }
        if (valueStatus !== undefined && valueStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>valueStatus, 'valueStatus');
        }
        if (remindAt !== undefined && remindAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>remindAt, 'remindAt');
        }
        if (reminderSent !== undefined && reminderSent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reminderSent, 'reminderSent');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }
        if (dueAt !== undefined && dueAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dueAt, 'dueAt');
        }
        if (delegate !== undefined && delegate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>delegate, 'delegate');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (delegateId !== undefined && delegateId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>delegateId, 'delegateId');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (affectedCustomerOrganisation !== undefined && affectedCustomerOrganisation !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedCustomerOrganisation, 'affectedCustomerOrganisation');
        }
        if (topic !== undefined && topic !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>topic, 'topic');
        }
        if (comment !== undefined && comment !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>comment, 'comment');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (preDueTimeReminderSent !== undefined && preDueTimeReminderSent !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preDueTimeReminderSent, 'preDueTimeReminderSent');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (affectedCustomer !== undefined && affectedCustomer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedCustomer, 'affectedCustomer');
        }
        if (valueStatuses) {
            valueStatuses.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'valueStatuses');
            })
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (hasWig !== undefined && hasWig !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>hasWig, 'hasWig');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsDelegationPojo>(`${this.configuration.basePath}/delegations`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDelegationStatus(requestParameters: UpdateDelegationStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<DelegationPojo>;
    public updateDelegationStatus(requestParameters: UpdateDelegationStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<DelegationPojo>>;
    public updateDelegationStatus(requestParameters: UpdateDelegationStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<DelegationPojo>>;
    public updateDelegationStatus(requestParameters: UpdateDelegationStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const delegationStatusUpdateDto = requestParameters.delegationStatusUpdateDto;
        if (delegationStatusUpdateDto === null || delegationStatusUpdateDto === undefined) {
            throw new Error('Required parameter delegationStatusUpdateDto was null or undefined when calling updateDelegationStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<DelegationPojo>(`${this.configuration.basePath}/delegations/update-status`,
            delegationStatusUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
