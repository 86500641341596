/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CadenceDto { 
    id?: number;
    name: string;
    wigId: number;
    frequency: CadenceDto.FrequencyEnum;
    startTime: string;
    endTime: string;
    autoStart: boolean;
    playerIds?: Array<number>;
}
export namespace CadenceDto {
    export type FrequencyEnum = 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'QUARTERLY';
    export const FrequencyEnum = {
        Daily: 'DAILY' as FrequencyEnum,
        Weekly: 'WEEKLY' as FrequencyEnum,
        Monthly: 'MONTHLY' as FrequencyEnum,
        Quarterly: 'QUARTERLY' as FrequencyEnum
    };
}


