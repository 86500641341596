/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateLeadMeasureCommitmentDto { 
    startTime?: string;
    endTime?: string;
    preAcceptanceScore: number;
    acceptedScore?: number;
    acceptedBenchmarkScore?: number;
    processActionExecutionInstanceCommand?: UpdateLeadMeasureCommitmentDto.ProcessActionExecutionInstanceCommandEnum;
    suspensionReason?: Array<UpdateLeadMeasureCommitmentDto.SuspensionReasonEnum>;
}
export namespace UpdateLeadMeasureCommitmentDto {
    export type ProcessActionExecutionInstanceCommandEnum = 'START' | 'SUSPEND' | 'UPDATE_PRE_ACCEPTANCE_SCORE' | 'UNSUSPEND';
    export const ProcessActionExecutionInstanceCommandEnum = {
        Start: 'START' as ProcessActionExecutionInstanceCommandEnum,
        Suspend: 'SUSPEND' as ProcessActionExecutionInstanceCommandEnum,
        UpdatePreAcceptanceScore: 'UPDATE_PRE_ACCEPTANCE_SCORE' as ProcessActionExecutionInstanceCommandEnum,
        Unsuspend: 'UNSUSPEND' as ProcessActionExecutionInstanceCommandEnum
    };
    export type SuspensionReasonEnum = 'TASK_RE_PRIORITIZATION_BY_LINE_MANAGER' | 'SUSPENSION_FROM_WORK' | 'TASK_NO_LONGER_RELEVANT' | 'HEALTH_CHALLENGE';
    export const SuspensionReasonEnum = {
        TaskRePrioritizationByLineManager: 'TASK_RE_PRIORITIZATION_BY_LINE_MANAGER' as SuspensionReasonEnum,
        SuspensionFromWork: 'SUSPENSION_FROM_WORK' as SuspensionReasonEnum,
        TaskNoLongerRelevant: 'TASK_NO_LONGER_RELEVANT' as SuspensionReasonEnum,
        HealthChallenge: 'HEALTH_CHALLENGE' as SuspensionReasonEnum
    };
}


