/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TerminateProcessExecutionInstanceDto { 
    reasons?: Array<TerminateProcessExecutionInstanceDto.ReasonsEnum>;
}
export namespace TerminateProcessExecutionInstanceDto {
    export type ReasonsEnum = 'CHANGE_OF_PRIORITY' | 'TASK_RE_PRIORITIZATION_BY_LINE_MANAGER' | 'TASK_NO_LONGER_RELEVANT' | 'HEALTH_CHALLENGE';
    export const ReasonsEnum = {
        ChangeOfPriority: 'CHANGE_OF_PRIORITY' as ReasonsEnum,
        TaskRePrioritizationByLineManager: 'TASK_RE_PRIORITIZATION_BY_LINE_MANAGER' as ReasonsEnum,
        TaskNoLongerRelevant: 'TASK_NO_LONGER_RELEVANT' as ReasonsEnum,
        HealthChallenge: 'HEALTH_CHALLENGE' as ReasonsEnum
    };
}


